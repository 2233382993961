:root {
  --color-bg: #2C2C32;
  --color-active: #FF9999;
}

main {
    min-height: calc(100vh - 85px - 22px);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
main a {
    text-decoration: none;
    color: var(--color-bg);
}
main a:hover {
    text-decoration: underline;
}
  
  
.my_link{
    height:100%;
}
  
.my_link>div>h4{
    width: 100%;
    margin-left: 10px;
    font-weight: 100;
}
  
.list-group{
    border-radius: 0.75rem;
}

/* Style the navigation bar. */
.mobile-nav {
    background: #F1F1F1;
    position: fixed;
    bottom: 0;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .bloc-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
  }
  .bloc-icon i {
    /* width: 30px; */
    font-size: 30pt;
    color:black;
  }
  .bloc-icon:hover i{
    /* color:white; */
    text-shadow: 1px 3px 3px black;
  }
  @media screen and (min-width: 600px) {
    .mobile-nav {
    display: none;
    }
    .navbar-nav{
        display: none;
    }
  }
  @media screen and (max-width: 600px) {
    .container .navbar-nav{
        display: none;
    }
  }
/* Style the navigation bar. */


.myCard:hover{
    background-color: lightskyblue;
    cursor:pointer;
    box-shadow: 10px 5px 5px black;
}

.sale4table thead a {
  width: 100%;
  height: 100%;
  cursor:pointer;
}

.sale4table thead th, .sale5table thead th, .sale6table thead th {
  position: relative;
  
}

.sale4table thead th.ascending::after, .sale6table thead th.ascending::after {
  content: '↓';
  display: inline-block;
  margin-left: 1em;
  position: absolute;
  top:50%;
  right:0px;
  margin-top: -8px;
}

.sale4table thead th.descending::after,.sale6table thead th.descending::after {
  content: '↑';
  display: inline-block;
  margin-left: 1em;
  position: absolute;
  top:50%;
  right:0px;
  margin-top: -8px;
}

.sale4table thead th:first-child, .sale4table tbody td:first-child, .sale4table tfoot th:first-child{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  min-width: 20px;
  /* background:inherit; */
 }

 .sale4table thead th:nth-child(2), .sale4table tbody td:nth-child(2), .sale4table tfoot th:nth-child(2) {
  position: -webkit-sticky; /* for Safari */
   position: sticky;
   left: 29px;
   min-width: 150px;
  /* background:inherit; */
 }

 .sale4table thead th:nth-child(3), .sale4table tbody td:nth-child(3), .sale4table tfoot th:nth-child(3) {
  position: -webkit-sticky; /* for Safari */
   position: sticky;
   left: 179px;
   min-width: 150px;
    /* background:inherit; */
 }

 .sale4table thead  th, .sale4tablem thead  th,  .sale5table thead  th,  .sale6table thead  th{
  position: -webkit-sticky; /* for Safari */
   position: sticky;
   top: 0px;
   z-index: 11;
 
 }

 .sale4table thead  th:nth-child(1), .sale4table thead  th:nth-child(2), .sale4table thead  th:nth-child(3){
  z-index:50;
     /* background:silver; */
 }



 .container .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: #000;
  font-weight:800;
}

/* 
.editinput:focus{
  position: absolute;
  max-width: inherit;
} */



.sale6table tbody tr td{
  opacity: 0.8;
}